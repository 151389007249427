import Image from "next/image";

export default function AuthenticationButton({ buttonType, onClick }) {
  switch (buttonType) {
    case "google": {
      return (
        <button
          className="google-login my-2 align-middle"
          style={{
            fontFamily: "Roboto",
            background: "#4285F4",
            padding: 0,
            paddingRight: 20,
            border: 0,
            borderRadius: 2,
            color: "white",
          }}
          // @ts-ignore
          variant="primary"
          onClick={onClick}
        >
          <div className="flex items-center">
            <Image
              priority={true}
              height="50"
              width="50"
              className="mr-2"
              src="/images/GoogleLogin.svg"
            />
            <p className="ml-2">Login with Google</p>
          </div>
        </button>
      );
    }
  }
}
