import React, { useState, useContext, createContext, ReactNode } from 'react';
import Notification from '@/components/Notification';
import { NotificationProps } from '@/interfaces/Notification'; // Assuming this is where the type is defined
import { getUID } from './helpers';

interface NotificationContextValue {
  addNotification: (notification: Omit<NotificationProps, 'uID' | 'removeNotification'>) => void;
}

const NotificationContext = createContext<NotificationContextValue | undefined>(undefined);

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);

  const addNotification = (newNotification: Omit<NotificationProps, 'uID' | 'removeNotification'>) => {
    const notificationWithID: NotificationProps = { ...newNotification, uID: getUID() };
    setNotifications((prevNotifications) => [...prevNotifications, notificationWithID]);
  };

  const removeNotification = (uID: string) => {
    setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.uID !== uID));
  };

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {children}
      <div className="pointer-events-none fixed inset-0 z-40 flex flex-col items-end space-y-2 px-4 py-6 sm:items-start sm:p-6 md:mr-4">
        {notifications.map((notification) => (
          <Notification
            key={notification.uID}
            uID={notification.uID}
            title={notification.title}
            body={notification.body}
            style={notification.style}
            removeNotification={() => removeNotification(notification.uID)}
            link={notification.link}
          />
        ))}
      </div>
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};
