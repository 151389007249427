import { v4 as uuidv4 } from "uuid";
const tokenizer = require("sbd");

export const parseBase64ToJson = (base64String) => {
  try {
    const decodedString = Buffer.from(base64String, "base64")
      .toString("utf8")
      .replace(/\n/g, "");
    return JSON.parse(decodedString);
  } catch (error) {
    console.error("Error parsing base64 JSON string:", error);
    throw new Error("Error parsing base64 JSON string:", error);
  }
};

export const displayVideoTime = (timeInSeconds) => {
  const minutes = ("0" + Math.floor(timeInSeconds / 60)).slice(-2);
  const seconds = ("0" + Math.round(timeInSeconds - minutes * 60)).slice(-2);
  return `${minutes}:${seconds}`;
};

export const isServer = () => {
  return !(typeof window != "undefined" && window.document);
};

// Truncate text:
export const truncateText = (text, maxLength = 300) => {
  const optional_options = {};
  if (text.length > maxLength) {
    try {
      // Return all of the sentences apart from the last one:
      const sentences = tokenizer.sentences(text, optional_options);

      // Remove the last item from the array:
      const lastSentence = sentences.pop();

      // Rejoin the sentences:
      const truncatedText = sentences.join(" ");
      return truncatedText;
    } catch (e) {
      // If the last symbol is a period, ! or ? then don't add ...:
      if (
        text.slice(-1) === "." ||
        text.slice(-1) === "!" ||
        text.slice(-1) === "?"
      ) {
        return text.slice(0, maxLength);
      } else {
        return text.substring(0, maxLength) + "...";
      }
    }
  } else {
    return text;
  }
};

export const generatePassword = () => {
  const chars =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let password = "";
  for (let i = 0; i < 6; i++) {
    password += chars[Math.floor(Math.random() * chars.length)];
  }
  return password;
};

// Calculate the number of free courses by quertying the second level key of access:
export const countCourseAccessCount = (allSimulatorData) => {
  let courseAccess = {
    Free: 0,
    Premium: 0,
  };

  Object.keys(allSimulatorData).forEach((simulatorDataKey) => {
    let simulatorData = allSimulatorData[simulatorDataKey];
    if (simulatorData?.access && simulatorData?.access === "Free") {
      courseAccess.Free += 1;
    } else if (simulatorData?.access && simulatorData?.access === "Premium") {
      courseAccess.Premium += 1;
    }
  });
  return courseAccess;
};

// Find the plan by the name of it in an array:
export const findPlanByName = (plans, name) => {
  return plans.find((plan) => plan.name === name);
};

// Finds the most recent course based on the updatedAt key:
export const findMostRecentCourseBasedOnupdatedAtKey = (courses) => {
  let mostRecentCourse = undefined;
  let mostRecentCourseKey = undefined;
  for (const [courseKey, courseValue] of Object.entries(courses)) {
    if (
      mostRecentCourse === undefined &&
      mostRecentCourseKey === undefined &&
      courseValue?.updatedAt
    ) {
      mostRecentCourse = courseValue.updatedAt;
      mostRecentCourseKey = courseKey;
    } else if (
      courseValue?.updatedAt &&
      mostRecentCourse &&
      mostRecentCourseKey &&
      courseValue?.updatedAt > mostRecentCourse
    ) {
      mostRecentCourse = courseValue.updatedAt;
      mostRecentCourseKey = courseKey;
    } else {
      continue;
    }
  }
  if (mostRecentCourse) {
    const mostRecentCourseValue = courses[mostRecentCourseKey];
    return { [mostRecentCourseKey]: mostRecentCourseValue };
  } else {
    return undefined;
  }
};
export const getSlugFromStr = (str) => {
  return str
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
};

// Returns a service account credential from base64 text:
export const retrieveServiceAccount = () => {
  return JSON.parse(
    Buffer.from(
      process.env.BASE_64_ENCODED_SERVICE_ACCOUNT_KEY,
      "base64"
    ).toString()
  );
};

export async function runMiddleware(req, res, fn) {
  return new Promise((resolve, reject) => {
    fn(req, res, (result) => {
      if (result instanceof Error) {
        return reject(result);
      }
      return resolve(result);
    });
  });
}

export function chooseRandomElement(array) {
  return array[Math.floor(Math.random() * array.length)];
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertUnitAmountToDecimal(decimalNumber) {
  return decimalNumber / 100.0;
}

export async function checkEarlySupporterStatus(email) {
  const resp = await fetch("/api/check-supporter-status", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });

  // // Overwrite if they are an early supporter:
  if (resp.status === 200) {
    return true;
  }
  return false;
}

export async function sendNewUserToMailerLite(email, name, idToken) {
  try {
    return await fetch("/api/mailerlite-signup", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, name, idToken }),
    });
  } catch (error) {
    console.error(error);
  }
}

export function getUTCTimestamp() {
  return Math.floor(new Date().getTime() / 1000);
}

export function displayUTCTimestamp(UTCTimestamp) {
  // https://coderrocketfuel.com/article/convert-a-unix-timestamp-to-a-date-in-vanilla-javascript
  const dateObject = new Date(parseInt(UTCTimestamp) * 1000);
  const month = dateObject.toLocaleString("en-US", { month: "long" });
  const year = dateObject.toLocaleString("en-US", { year: "numeric" });
  return `${month} ${year}`;
}

export function identifyLink(text) {
  return /\[(.*?)\]\((.*?)\)/gim.test(text);
}

export function renderLink(link) {
  const href = link.replace(/\[(.*?)\]\((.*?)\)/gim, "$2");
  const text = link.replace(/\[(.*?)\]\((.*?)\)/gim, "$1");
  return (
    <a
      href={href}
      className="cursor-pointer text-indigo-600 hover:underline"
      target="_blank"
    >
      {text}
    </a>
  );
}

export function parseMarkdownLinks(text) {
  return text.replace(
    /\[(.*?)\]\((.*?)\)/gim,
    '<a href="$2" target="_blank">$1</a>'
  );
}

export function findNextSectionID(sectionID, sections, chapterID) {
  const thisSection = sections.find(
    (section) => section.sectionID === sectionID
  );
  let nextSectionIndex = sections.indexOf(thisSection) + 1;
  if (nextSectionIndex >= sections.length) {
    return `end_${chapterID}`;
  }
  const nextSection = sections[nextSectionIndex];
  const nextSectionID = nextSection.sectionID;

  return nextSectionID;
}
export function findChapterIndex(chapterID, chapters) {
  const thisChapter = chapters.find(
    (chapter) => chapter.chapterID === chapterID
  );
  let chapterIndex = chapters.indexOf(thisChapter) + 1;

  return chapterIndex;
}

export function findNextChapter(chapterID, chapters) {
  const thisChapter = chapters.find(
    (chapter) => chapter.chapterID === chapterID
  );
  let nextChapterIndex = chapters.indexOf(thisChapter) + 1;
  if (nextChapterIndex >= chapters.length) {
    nextChapterIndex = 0;
  }
  const nextChapter = chapters[nextChapterIndex];

  return nextChapter;
}

export function getUID() {
  return (
    new Date().getTime().toString(36).substring(0, 5) +
    Math.random().toString(36).substring(2, 7)
  );
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function isValidEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function rando(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function getPhotoFilename(photoURL) {
  // handle botPics
  // https://avatars.dicebear.com/api/bottts/870b07359bfe1aab668a604cfd36c0fdc8706d22.svg
  if (photoURL.includes("avatars.dicebear.com")) {
    // return photoURL.split("/").slice(-1)[0];
    return "randomBotPic.svg";
  }
  return photoURL
    .split("?")[0]
    .replace("https://firebasestorage.googleapis.com/v0/b/", "")
    .replace("vexpower-2b2c5.appspot.com", "")
    .replace("vexpower-development.appspot.com", "")
    .replace("/o/images%2F", "")
    .replace("/courses%2F", "");
}

export function getVideoFilename(videoURL) {
  return videoURL
    .split("?")[0]
    .replace("https://firebasestorage.googleapis.com/v0/b/", "")
    .replace("vexpower-2b2c5.appspot.com", "")
    .replace("vexpower-development.appspot.com", "")
    .replace("/o/videos%2F", "");
}

export function youtube_parser(url) {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

export function getBotPic(displayName) {
  // https://avatars.dicebear.com/styles/bottts
  return `https://avatars.dicebear.com/api/bottts/${displayName}/${uuidv4()}.svg`;
}

export function getBotName() {
  const emotions = [
    "happy",
    "sad",
    "mad",
    "relieved",
    "unhappy",
    "paranoid",
    "content",
    "proud",
    "lustful",
    "greedy",
    "angry",
    "envious",
    "lazy",
    "gluttonous",
    "humble",
    "charitable",
    "grateful",
    "temperate",
    "patient",
    "diligent",
    "relieved",
    "joyful",
    "satisfied",
    "lovely",
    "content",
    "worried",
    "stressed",
    "anxious",
    "lonely",
    "troubled",
    "disappointed",
    "gloomy",
    "doubtful",
    "panicked",
    "nervous",
    "confused",
    "grumpy",
    "confused",
    "sleepy",
    "dopey",
    "bashful",
    "adventurous",
    "mischievious",
    "marvelous",
    "funny",
    "mean",
    "brave",
    "clumsy",
    "fabulous",
    "shy",
    "curious",
    "helpful",
    "scared",
    "busy",
    "silly",
    "vengeful",
  ];

  const robots = [
    "android",
    "robot",
    "automaton",
    "droid",
    "machine",
    "bot",
    "computer",
    "golem",
    "bionic",
    "cyborg",
    "synthetic",
    "mecha",
    "cybernetic",
    "drone",
    "AI",
    "humanoid",
    "colossus",
  ];

  return `${rando(emotions)}-${rando(robots)}-${
    Math.floor(Math.random() * 99) + 1
  }`;
}
