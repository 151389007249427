import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { LoginIcon } from "@heroicons/react/outline";
import { InboxInIcon, MailIcon, XIcon } from "@heroicons/react/outline";

// Custom imports:
import { classNames, isValidEmail } from "@/lib/helpers";
import firebase from "firebase/app";
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
import AuthenticationButton from "./AuthenticationButton";
import { useAuth } from "@/lib/provider-authentication";
import { useNotifications } from "@/lib/provider-notifications";

export default function LoginModal({ modalOpen, setModalOpen }) {
  const { email, setEmail } = useAuth();
  const [inputEmail, setInputEmail] = useState("");
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const { addNotification } = useNotifications();

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        open={modalOpen}
        onClose={setModalOpen}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-dark bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform overflow-hidden bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                <button
                  type="button"
                  className="focus:outline-none bg-white text-gray-400 hover:text-gray-dark"
                  onClick={(e) => {
                    e.preventDefault;
                    setModalOpen(false);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <div className="mx-2 mt-6 sm:flex sm:items-start">
                {magicLinkSent ? (
                  <div className="gray-light mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <InboxInIcon
                      className="h-6 w-6 text-tertiary"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <MailIcon
                      className="h-6 w-6 text-secondary"
                      aria-hidden="true"
                    />
                  </div>
                )}

                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  {magicLinkSent ? (
                    <>
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-black"
                      >
                        Check your Inbox!
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-dark">
                          Your aren&apos;t logged in yet! We&apos;ve sent a
                          magic link to your inbox, just click the link to
                          login.
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-black"
                      >
                        Access Account
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-dark">
                          Enter your email to login/signup. We&apos;ll send a
                          magic link to your inbox, just click the link to
                          login.
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* Email form */}
              <div className="mx-auto mt-8 max-w-xl px-4 sm:px-6 lg:mx-0 lg:px-8 lg:text-left">
                {magicLinkSent ? (
                  <>
                    <p className="mb-4">
                      An email has been sent to{" "}
                      <span className="text-tertiary">{email}</span>, please
                      check your inbox to finish logging in.
                    </p>
                    <a
                      href="#"
                      onClick={async (e) => {
                        e.preventDefault();
                        const actionCodeSettings = {
                          url: window.location.href,
                          handleCodeInApp: true,
                        };

                        await firebase
                          .auth()
                          .sendSignInLinkToEmail(inputEmail, actionCodeSettings)
                          .then(() => {
                            setMagicLinkSent(true);
                            localStorage.setItem("email", inputEmail);
                            setEmail(inputEmail);
                          })
                          .catch(function (error) {
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            console.error(
                              "There was an error sending the magic link: " +
                                errorCode +
                                ", " +
                                errorMessage
                            );
                          });
                      }}
                      className={
                        "group-hover:text-indigo-70focus:outline-none h-11 w-full text-sm font-medium text-secondary focus:ring-2 focus:ring-secondary focus:ring-offset-2 md:h-full"
                      }
                    >
                      <Transition
                        as={Fragment}
                        show={true}
                        enter="ease-out duration-[20000ms]"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                      >
                        <span className="text-xs sm:text-sm">
                          {" "}
                          Nothing yet? Re-send link{" "}
                        </span>
                      </Transition>
                    </a>
                  </>
                ) : (
                  <>
                    <form className="mt-3 flex w-full flex-col sm:w-auto md:flex-row">
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <input
                        onChange={(e) => setInputEmail(e.target.value)}
                        value={inputEmail}
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="email"
                        required
                        className="focus:outline-none flex-1 border-2 border-gray-200 px-4 py-4 text-sm placeholder-gray-dark shadow-sm focus:ring-2 focus:ring-secondary sm:text-base "
                        placeholder="Enter your email"
                      />
                      <div>
                        <button
                          type="submit"
                          disabled={!isValidEmail(inputEmail)}
                          onClick={async (e) => {
                            e.preventDefault();
                            const actionCodeSettings = {
                              url: window.location.href,
                              handleCodeInApp: true,
                            };
                            await firebase
                              .auth()
                              .sendSignInLinkToEmail(
                                inputEmail,
                                actionCodeSettings
                              )
                              .then(() => {
                                setMagicLinkSent(true);
                                localStorage.setItem("email", inputEmail);
                                setEmail(inputEmail);
                              })
                              .catch(function (error) {
                                const errorCode = error.code;
                                const errorMessage = error.message;
                                console.error(
                                  "There was an error sending the magic link: " +
                                    errorCode +
                                    ", " +
                                    errorMessage
                                );
                              });
                          }}
                          className={classNames(
                            !isValidEmail(inputEmail)
                              ? "h-11 w-full opacity-50 md:h-full"
                              : "",
                            "group focus:outline-none flex h-full w-full items-center justify-center border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-gray-light hover:bg-primary focus:ring-2 focus:ring-secondary focus:ring-offset-2 group-hover:text-indigo-200 md:h-full"
                          )}
                        >
                          <LoginIcon
                            className="mr-2 h-4 w-4 text-gray-light group-hover:text-indigo-200 sm:h-5 sm:w-5"
                            aria-hidden="true"
                          />
                          <span className="text-xs sm:text-sm">LOGIN</span>
                        </button>
                      </div>
                    </form>
                    <div className="text-md mt-4 pt-2 font-medium leading-6 text-background-dark">
                      Or Single Sign-on
                    </div>
                    {/* Google Link */}
                    <div className="flex max-w-xs flex-col  pt-2">
                      <AuthenticationButton
                        buttonType="google"
                        onClick={() => {
                          firebase
                            .auth()
                            .signInWithPopup(googleAuthProvider)
                            .then(({ user }) => {
                              setModalOpen(false);
                              addNotification({
                                style: "success",
                                title: "You're all logged in",
                                body: `You are now logged in with the email address ${user?.email}.`,
                              });
                            });
                        }}
                      />
                    </div>
                    <p className="mt-3 text-sm text-gray-dark">
                      If you don&apos;t already have an account, we&apos;ll
                      create one for you. Read our{" "}
                      <a
                        href="https://www.vexpower.com/privacy"
                        className="font-medium text-black underline"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
