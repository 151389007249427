import axios from "axios";
import React, { useState, useEffect, useContext, createContext } from "react";
import { parseBase64ToJson } from "./helpers";
import { useRouter } from "next/router";
import { StripePriceIds } from "@/interfaces/StripeConfigurationObject";

export interface DevelopmentContextState {
    checkedEnvironment: boolean;
    stripePriceIDs: StripePriceIds | null;
    stripeDiscountCode: string | undefined;
}

const DevelopmentContext = createContext<DevelopmentContextState | undefined>(undefined);

export const DevelopmentContextProvider: React.FC = ({ children }) => {
  const [checkedEnvironment, setCheckedEnvironment] = useState(false);
  const [stripePriceIDs, setStripePriceIDs] = useState<StripePriceIds | null>(null);
  const [stripeDiscountCode, setStripeDiscountCode] = useState<string | undefined>(undefined);

  const router = useRouter();
  // 1. Extract the discount code query parameters:
  const { code } = router.query;
  if (code && stripeDiscountCode !== code && typeof code === 'string') {
    /* TODO - Add a check to see whether this a valid promotional code ID or not. 
    (This is not a name but an promotion ID)
    */
    localStorage.setItem("promotion_code", code as string);
    setStripeDiscountCode(code);
  }

  const updateDevContext = async () => {
    // 2. Extracting the stripe ids:
    const stripeIDs: StripePriceIds = parseBase64ToJson(process.env.NEXT_PUBLIC_STRIPE_CONFIGURATION_OBJECT)
    setStripePriceIDs(stripeIDs);

    // 3. Check if its a holiday:
    const holidayRes = await axios.get("/api/check-holiday/");
    const data = holidayRes.data;
    const holidayCodesToCheck = stripeIDs.holidayCodesToCheck

    // The 30FORLIFE is only for holidays:
    if (data.isHoliday) {
      if (!stripeDiscountCode && !localStorage.getItem("promotion_code")) {
        try {
          const coupon = stripeIDs?.PromotionCodes[data?.HOLIDAY_CODE_NAME];
          if (coupon) {
            setStripeDiscountCode(coupon);
            localStorage.setItem("promotion_code", coupon);
          }
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      if (
        holidayCodesToCheck.includes(localStorage.getItem("promotion_code")) ||
        holidayCodesToCheck.includes(stripeDiscountCode)
      ) {
        // Delete the promotion_code:
        localStorage.removeItem("promotion_code");
        setStripeDiscountCode(undefined);
      }
    }

    // 4. If there is no Stripe Discount code, check in localStorage:
    if (!stripeDiscountCode) {
      if (localStorage.getItem("promotion_code")) {
        setStripeDiscountCode(localStorage.getItem("promotion_code"));
      }
    }

    // Double checked all of the environment variables:
    setCheckedEnvironment(true);
  };

  useEffect(() => {
    updateDevContext();
  }, []);

  return (
    <DevelopmentContext.Provider
      value={{
        checkedEnvironment,
        stripePriceIDs,
        stripeDiscountCode,
      }}
    >
      {children}
    </DevelopmentContext.Provider>
  );
};

export const useDevelopmentContext = () => {
  const context = useContext(DevelopmentContext);
  if (context === undefined) {
    throw new Error('useDevelopmentContext must be used within a DevelopmentContextProvider');
  }
  return context;
};
