import "@/styles/globals.css";
import "@/styles/styles.css";

// CSS for Algolia:
import "instantsearch.css/themes/satellite.css";
import "@/styles/customsearch.css";

import { DefaultSeo } from "next-seo";
import { useEffect } from "react";
import { useRouter } from "next/router";
import Script from "next/script";

import { AuthProvider } from "@/lib/provider-authentication";
import { DevelopmentContextProvider } from "@/lib/provider-development-environment";
import { LoginProvider } from "@/lib/login";
import { NotificationProvider } from "@/lib/provider-notifications";
import { SearchContextProvider } from "@/lib/provider-useSearchModal";
import { GTM_ID, pageview, gtmPurchase } from "@/lib/analytics/gtmHelpers";

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const executePageView = () => pageview(window.location.href);

  useEffect(() => {
    // Get the query parameters:
    const urlParams = new URLSearchParams(window.location.search);
    // Extract the type paramter:
    const type = urlParams.get("type");
    // Fire a GTM event:
    if (type) {
      gtmPurchase(type);
      // Clear the type paramter from the URL:
      router.replace(router.pathname, undefined, { shallow: true });
    }
  }, [router.asPath, router]);

  useEffect(() => {
    router.events.on("routeChangeComplete", executePageView);
    return () => {
      router.events.off("routeChangeComplete", executePageView);
    };
  }, [router.events]);

  return (
    <>
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
        }}
      />
      <DevelopmentContextProvider>
        <NotificationProvider>
          <AuthProvider>
            <LoginProvider>
              <SearchContextProvider>
                <>
                  {/* Next SEO Global Meta Data: */}
                  <DefaultSeo
                    title="Vexpower Simulator-Based Courses"
                    description="Learn Data-Driven Marketing in simulator-based courses using real-life scenarios."
                    openGraph={{
                      type: "website",
                      locale: "en_IE",
                      url: "https://app.vexpower.com/",
                      site_name: "Vexpower",
                      images: [
                        {
                          url: "https://uploads-ssl.webflow.com/5f93e437229cf0448ec06084/619f6aaaabba0da8d048a11e_Frame%203.png",
                          width: 800,
                          height: 600,
                          alt: "Og Image Alt",
                          type: "image/png",
                        },
                      ],
                    }}
                    twitter={{
                      handle: "@vexpower_",
                      site: "https://app.vexpower.com/",
                      cardType: "summary_large_image",
                    }}
                  />
                  <Component {...pageProps} />
                </>
              </SearchContextProvider>
            </LoginProvider>
          </AuthProvider>
        </NotificationProvider>
      </DevelopmentContextProvider>
    </>
  );
}

export default MyApp;
