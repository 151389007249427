import { Fragment, useState, useRef, useEffect } from "react";
import { Transition } from "@headlessui/react";
import {
  BanIcon,
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  LightBulbIcon,
  ArrowRightIcon,
} from "@heroicons/react/outline";
import { BellIcon, XIcon } from "@heroicons/react/solid";
import { NotificationProps } from "@/interfaces/Notification";

export default function Notification({
  uID,
  title,
  body,
  style,
  removeNotification,
  link,
}: NotificationProps) {
  const [show, setShow] = useState(false);
  const removeNotificationRef = useRef(removeNotification);
  removeNotificationRef.current = removeNotification;

  let clickTimer: ReturnType<typeof setTimeout>;  // Define the type for clickTimer
  const transitionTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const removeTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const durationTime = 3000;
  const transitionTime = 350;

useEffect(() => {
    setShow(true);
    transitionTimer.current = setTimeout(() => {
      setShow(false);
    }, durationTime - transitionTime);

    removeTimer.current = setTimeout(() => {
      // This is the correct usage - calling removeNotification with uID
      removeNotification(uID);
    }, durationTime);

    return () => {
      // Clear timeouts
      if (clickTimer) clearTimeout(clickTimer);
      if (transitionTimer.current) clearTimeout(transitionTimer.current);
      if (removeTimer.current) clearTimeout(removeTimer.current);
    };
  }, [uID, removeNotification, clickTimer]);
  const linkTarget = link?.target || "_self";
  return (
    <>
      <div
        className="flex w-full flex-col space-y-4 sm:items-end"
        onMouseEnter={() => {
          clearTimeout(transitionTimer.current);
          clearTimeout(removeTimer.current);
        }}
      >
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pointer-events-auto z-40 max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 sm:w-full">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {/* https://stackoverflow.com/questions/46592833/how-to-use-switch-statement-inside-a-react-component */}
                  {{
                    primary: (
                      <BellIcon
                        className="h-6 w-6 text-secondary"
                        aria-hidden="true"
                      />
                    ),
                    secondary: (
                      <LightBulbIcon
                        className="h-6 w-6 text-tertiary"
                        aria-hidden="true"
                      />
                    ),
                    success: (
                      <CheckCircleIcon
                        className="h-6 w-6 text-primary"
                        aria-hidden="true"
                      />
                    ),
                    danger: (
                      <BanIcon
                        className="h-6 w-6 text-error"
                        aria-hidden="true"
                      />
                    ),
                    warning: (
                      <ExclamationIcon
                        className="h-6 w-6 text-warning"
                        aria-hidden="true"
                      />
                    ),
                    info: (
                      <InformationCircleIcon
                        className="h-6 w-6 text-gray-dark"
                        aria-hidden="true"
                      />
                    ),
                  }[style] || (
                    <BellIcon
                      className="h-6 w-6 text-secondary"
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-xs font-medium text-black sm:text-sm">
                    {title}
                  </p>
                  <p className="mt-1 text-xs text-gray-dark sm:text-sm">
                    {body}
                  </p>

                  {link ? (
                    <button className="mt-2">
                      <a href={link.href} target={linkTarget}>
                        <p>
                          <span className="flex items-center text-blue-500 hover:text-blue-700">
                            {link.text}{" "}
                            <ArrowRightIcon
                              className="ml-2"
                              height={15}
                              width={15}
                            />
                          </span>
                        </p>
                      </a>
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    className="focus:outline-none inline-flex rounded-md bg-white text-gray-400 hover:text-gray-dark focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                    onClick={() => {
                      setShow(false);
                      clickTimer = setTimeout(() => {
                        removeNotification(uID);
                      }, transitionTime);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
}
