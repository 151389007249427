import React, { useState, useContext, createContext, ReactNode } from "react";
import LoginModal from "@/components/login/LoginModal";

// Define the type for the context value
type LoginContextType = {
  setModalOpen: (isOpen: boolean) => void,
};

// prettier-ignore
const LoginContext = createContext<LoginContextType>({
  setModalOpen: () => {}
});

// Define the type for the props of LoginProvider
type LoginProviderProps = {
  children: ReactNode,
};

export const LoginProvider = ({ children }: LoginProviderProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <LoginContext.Provider value={{ setModalOpen }}>
      <LoginModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = (): LoginContextType => useContext(LoginContext);
