/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";

// Site search functionality:
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, SearchBox, Hits } from "react-instantsearch-hooks-web";
import HitsContainer from "@/components/algolia/hits";

const searchClient = algoliasearch(
  "3TPY2690GE",
  "600d62f84331ca4aa683eb86c5a25075"
);

export default function SearchUI({ isSearchOpen, setIsSearchOpen }) {
  return (
    <Transition.Root show={isSearchOpen} as={"div"}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setIsSearchOpen(false);
        }}
      >
        <Transition.Child
          as={"div"}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div
          onClick={() => setIsSearchOpen(false)}
          className="fixed inset-0 z-10 overflow-y-auto"
        >
          <div className="mt-20 flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <div
              className="relative mx-auto h-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-4/5 sm:p-6 lg:max-w-4xl"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                <button
                  type="button"
                  className="focus:outline-none rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => setIsSearchOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XCircleIcon className="h-8 w-8" aria-hidden="true" />
                </button>
              </div>
              <div>
                <div className="text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="px-3 pt-14 text-center text-2xl font-bold tracking-tight text-gray-800 "
                  >
                    Search Courses
                  </Dialog.Title>
                  <div className="mt-4">
                    <InstantSearch
                      indexName="prod_vexpower_courses"
                      searchClient={searchClient}
                      stalledSearchDelay={300}
                    >
                      <div className="mx-auto w-1/2">
                        <SearchBox
                          placeholder="Search for a course."
                          autoFocus={true}
                          searchAsYouType={true}
                          onClick={(e) => {}}
                        />
                      </div>

                      <div className="m-auto mt-4 h-96 w-2/3 overflow-y-auto">
                        <Hits hitComponent={HitsContainer} />
                      </div>
                      {/* <Pagination /> */}
                    </InstantSearch>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6"></div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
