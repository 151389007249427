import firebase from "firebase";

export const deleteFromLocalStorageAndRealTimeDB = (
  uid: firebase.User["uid"],
  eventName: "login" | "userIDSet"
) => {
  // Delete from localStorage:
  localStorage.removeItem(eventName);
  // Delete from RealTimeDB:
  const db = firebase.database();
  const userRef = db.ref(`users/${uid}`);
  return userRef.once("value", (snapshot) => {
    const userData = snapshot.val();
    if (userData?.[eventName]) {
      userRef.update({
        [eventName]: null,
      });
    }
  });
};

export const checkCourseForEvent = async (
  user: firebase.User,
  courseID: string,
  courseEvent: "tutorial_begin" | "tutorial_complete"
) => {
  // Check localStorage with the course ID concatenated with the courseEvent:
  if (localStorage.getItem(`${courseID}_${courseEvent}`) === "true") {
    return true;
  }
  if (!user) return false;

  // Check the server:
  const db = firebase.database();
  const userRef = db.ref(`users/${user.uid}`);
  userRef.once("value", (snapshot) => {
    const userData = snapshot.val();
    // Check the users courses object to see if the courseID exists:
    if (!userData?.courses?.courseID) return false;
    // If the courseID exists, check if the courseEvent exists:
    if (userData?.courses?.courseID?.courseEvent) {
      // If the courseEvent exists, set the event in localStorage
      localStorage.setItem(`${courseID}_${courseEvent}`, "true");
      return true;
    } else {
      return false;
    }
  });
};

export const checkForUserEvent = async (
  user: firebase.User,
  eventName: "login" | "signup" | "logout" | "share" | "userIDSet"
) => {
  if (!user) return false;
  // Check for the eventName in localStorage:
  if (localStorage.getItem(eventName) === "true") {
    return true;
  }

  // Check the server:
  const db = firebase.database();
  const userRef = db.ref(`users/${user.uid}`);
  // Get the data once:
  const userData = await userRef.once("value");

  if (userData?.[eventName]) {
    // If the event exists, set the event in localStorage
    localStorage.setItem(eventName, "true");
    return true;
  } else {
    return false;
  }
};

export const updateCourseHasCompletedInRealTimeDB = (
  user: firebase.User,
  courseID: string,
  courseEvent: "tutorial_begin" | "tutorial_complete"
) => {
  const db = firebase.database();
  const userRef = db.ref(`users/${user.uid}`);
  return userRef.once("value", (snapshot) => {
    const userData = snapshot.val();
    // Check the users courses object to see if the courseID exists:
    if (!userData?.courses[courseID]) return;
    // If the courseID exists, set/update for the courseEvent:
    userRef.child(`courses/${courseID}`).update({
      [courseEvent]: true,
    });
    // Set the event in localStorage
    localStorage.setItem(`${courseID}_${courseEvent}`, "true");
  });
};

export const updateUserInRealTimeDB = (
  user: firebase.User,
  eventName: "login" | "signup" | "logout" | "share" | "userIDSet",
  crudType: "set" | "reverse",
  setValue?: boolean,
  // Make setLocalStorage default to true, add typescript boolean type:
  setLocalStorage: boolean = true
) => {
  const db = firebase.database();
  const userRef = db.ref(`users/${user.uid}`);
  return userRef.once("value", (snapshot) => {
    const userData = snapshot.val();
    if (crudType === "set") {
      if (setValue) {
        userRef.update({
          [eventName]: setValue,
        });
        if (setLocalStorage) {
          localStorage.setItem(eventName, setValue ? "true" : "false");
        }
      } else {
        userRef.update({
          [eventName]: true,
        });
        if (setLocalStorage) {
          localStorage.setItem(eventName, "true");
        }
      }
    } else {
      // Get the previous state of the event:
      const previousState: boolean = userData?.[eventName];
      // If the previous state to be the opposite:
      userRef.update({
        [eventName]: !previousState,
      });
      // Set the event in localStorage as a string:
      if (setLocalStorage) {
        localStorage.setItem(eventName, !previousState ? "true" : "false");
      }
    }
  });
};
