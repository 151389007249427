export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

type eventObject = {
  event: string;
  eventName: string;
  eventAction: string;
  eventCategory: string;
  eventLabel: string;
};

export const pageview = (url) => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  dataLayer.push({
    event: "pageview",
    page: url,
  });
  return url;
};

export const event = (eventObject) => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  dataLayer.push(eventObject);
};

const gtmUserIDSet = (user) => {
  if (!user) return;
  const eventObject = {
    event: "userIDSet",
    userID: user?.uid,
  }
  return event(eventObject);
};

// Use standard google event names
// https://support.google.com/analytics/answer/9267735
const gtmLogin = (method = "") => {
  const eventObject = {} as eventObject;
  eventObject.eventName = "login";
  eventObject.event = "Custom Event";
  eventObject.eventAction = "Log In";
  eventObject.eventCategory = "Authentication";
  eventObject.eventLabel = method;
  return event(eventObject);
};

const gtmPurchase = (type = "") => {
  const eventObject = {} as eventObject;
  eventObject.eventName = "purchase";
  eventObject.event = "Custom Event";
  eventObject.eventAction = "Purchase";
  eventObject.eventCategory = "Monetization";
  eventObject.eventLabel = type;
  return event(eventObject);
};

const gtmSignUp = (method = "") => {
  const eventObject = {} as eventObject;
  eventObject.eventName = "sign_up";
  eventObject.event = "Custom Event";
  eventObject.eventAction = "Sign Up";
  eventObject.eventCategory = "Authentication";
  eventObject.eventLabel = method;
  return event(eventObject);
};

const gtmLogout = (location = "") => {
  const eventObject = {} as eventObject;
  eventObject.eventName = "logout";
  eventObject.event = "Custom Event";
  eventObject.eventAction = "Log Out";
  eventObject.eventCategory = "Authentication";
  eventObject.eventLabel = location;
  return event(eventObject);
};

const gtmExerciseComplete = (exerciseID) => {
  const eventObject = {} as eventObject;
  eventObject.eventName = "select_content";
  eventObject.event = "Custom Event";
  eventObject.eventAction = "Exercise Complete";
  eventObject.eventCategory = "Course Progress";
  eventObject.eventLabel = exerciseID;
  return event(eventObject);
};

const gtmCourseStart = (courseID) => {
  const eventObject = {} as eventObject;
  eventObject.eventName = "tutorial_begin";
  eventObject.event = "Custom Event";
  eventObject.eventAction = "Course Start";
  eventObject.eventCategory = "Course Progress";
  eventObject.eventLabel = courseID;
  return event(eventObject);
};

const gtmCourseComplete = (courseID) => {
  const eventObject = {} as eventObject;
  eventObject.eventName = "tutorial_complete";
  eventObject.event = "Custom Event";
  eventObject.eventAction = "Course Complete";
  eventObject.eventCategory = "Course Progress";
  eventObject.eventLabel = courseID;
  return event(eventObject);
};

// Social sharing widgets:
const gtmSocialShare = (shareType) => {
  const eventObject = {} as eventObject;
  eventObject.eventName = "share";
  eventObject.event = "Custom Event";
  eventObject.eventAction = "Button Click";
  eventObject.eventCategory = "Social Sharing";
  eventObject.eventLabel = shareType;
  return event(eventObject);
};

// Feedback widgets:
const gtmFeedback = (btnName) => {
  const eventObject = {} as eventObject;
  eventObject.eventName = "select_content";
  eventObject.event = "Custom Event";
  eventObject.eventAction = "Button Click";
  eventObject.eventCategory = "Feedback";
  eventObject.eventLabel = btnName;
  return event(eventObject);
};

export {
  gtmPurchase,
  gtmUserIDSet,
  gtmLogin,
  gtmLogout,
  gtmSignUp,
  gtmExerciseComplete,
  gtmCourseComplete,
  gtmCourseStart,
  gtmSocialShare,
  gtmFeedback,
};
