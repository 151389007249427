import Image from "next/image";
import Link from "next/link";
import { useSearchModal } from "@/lib/provider-useSearchModal";
import { useRouter } from "next/router";

export default function HitsContainer({ hit }) {
  const { setIsSearchOpen } = useSearchModal();
  const router = useRouter();
  return (
    <div
      className="flex h-full w-full flex-col justify-between"
      onClick={() => {
        setIsSearchOpen(false);
        router.push(`/sim/${hit.slug}/`);
      }}
    >
      <div className="flex w-full cursor-pointer items-center justify-between space-x-2">
        <div>
          <h1 className="text-left text-lg">{hit.title}</h1>
          <div className="text-gray w-64 truncate text-left">{hit.topic}</div>
        </div>

        <Image src={hit.image} height={50} width={100} />
      </div>
    </div>
  );
}
