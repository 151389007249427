import firebase from "firebase";
import { FirebaseConfig } from "@/interfaces/FirebaseConfig";

const firebaseConfigString = process.env.NEXT_PUBLIC_FIREBASE_CONFIGURATION_OBJECT;
if (!firebaseConfigString) {
  throw new Error("Firebase configuration not found in environment");
}
const firebaseConfig: FirebaseConfig = JSON.parse(firebaseConfigString.replace(/\n/g,"")) as FirebaseConfig;
export const databaseURL = firebaseConfig["databaseURL"];

// Changing this to be the old hardcoded bucket URL for now:
export const storageBucket = 'vexpower-2b2c5.appspot.com'
// export const storageBucket = firebaseConfig["storageBucket"];

export default function firebaseClient() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
}