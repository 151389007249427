import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";
import React, { useState, useEffect, useContext, createContext } from "react";
import { useRouter } from "next/router";
import nookies from "nookies";

// TypeScript interfaces:
import { User } from "@/interfaces/User";
import { StripePriceIds } from "@/interfaces/StripeConfigurationObject";

// Custom imports:
import firebaseClient from "./firebaseClient";
import { parseBase64ToJson } from "./helpers";
import { getBotName, sendNewUserToMailerLite } from "./helpers";
import { gtmUserIDSet, gtmLogin, gtmSignUp } from "@/lib/analytics/gtmHelpers";
import { useNotifications } from "./provider-notifications";
import {
  checkForUserEvent,
  updateUserInRealTimeDB,
} from "./analytics/idempotentGtmTracking";

export const setCookies = async (firebaseUser) => {
  // Set the maxAge and expires to 1 hour:
  const maxAge = 60 * 60;
  const expires = new Date(Date.now() + maxAge * 1000);
  const idTokenResult = await firebaseUser.getIdTokenResult();
  nookies.set(undefined, "firebase_id_token", idTokenResult.token, {
    maxAge,
    expires,
    path: "/",
  });
};

// Creating an authentication provider:
interface AuthContextInterface {
  user: User;
  email: string;
  meta: any;
  initialized: boolean;
  hasSubscribed: boolean;
  hasUpgradedPlan: boolean;
  subscriptionPlan: string;
  subscriptionFinalStateSet: boolean;
  purchasedCourses: {};
  purchasedCoursesFinalStateSet: boolean;
  setMeta: (meta: any) => void;
  setEmail: (email: string) => void;
  setUser: (user: any) => void;
}

// prettier-ignore
const AuthContext = createContext({} as AuthContextInterface);

export const AuthProvider = ({ children }) => {
  firebaseClient();
  const [user, setUser] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [meta, setMeta] = useState({});
  const [email, setEmail] = useState("");
  const router = useRouter();
  const { addNotification } = useNotifications();

  const stripePriceIds: StripePriceIds = parseBase64ToJson(process.env.NEXT_PUBLIC_STRIPE_CONFIGURATION_OBJECT);

  // SUBSCRIPTION LOGIC:
  const [hasSubscribed, setHasSubscribed] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState("Free Basics");
  const [subscriptionFinalStateSet, setsubscriptionFinalStateSet] =
    useState(false);

  useEffect(() => {
    setsubscriptionFinalStateSet(false);
    setSubscriptionPlan("Free Basics");
    if (initialized && !user?.uid) {
      setHasSubscribed(false);
      setSubscriptionPlan("Free Basics");
      setsubscriptionFinalStateSet(true);
      return;
    } else {
      if (initialized && user?.uid) {
        const query = firebase
          .firestore()
          .collection("customers")
          .doc(user.uid)
          .collection("subscriptions")
          .orderBy("created", "desc")
          .where("status", "==", "active")
          .limit(1);

        const checkIfActiveSubscription = async (
          mostRecentSubscriptionSnapshot
        ) => {
          setsubscriptionFinalStateSet(false);

          if (mostRecentSubscriptionSnapshot.empty) {
            // There are no subscription documents, therefore there is no valid subscription:
            setHasSubscribed(false);
            setSubscriptionPlan("Free Basics");
            setsubscriptionFinalStateSet(true);
            return false;
          } else {
            const mostRecentSubscriptionData =
              await mostRecentSubscriptionSnapshot.docs[0].data();
            const secondsSinceEpoch = Math.round(Date.now() / 1000);

            // If the subscription has been canceled, i.e the canceled at key exists:
            // The user might still have some days remaining:
            if (mostRecentSubscriptionData?.canceled_at) {
              if (
                mostRecentSubscriptionData.current_period_end.seconds >
                secondsSinceEpoch
              ) {
                // Only show the notification if the day on .cancel_at is the same as the current day/mm/yyyy:
                if (
                  new Date(
                    mostRecentSubscriptionData.canceled_at.seconds * 1000
                  ).toDateString() === new Date().toDateString()
                ) {
                  addNotification({
                    style: "info",
                    title: `Plan has been downgraded`,
                    body: `Your subscription has been canceled. You will still have access until ${new Date(
                      mostRecentSubscriptionData.current_period_end.seconds *
                        1000
                    )}`,
                  });
                }
                setHasSubscribed(true);
                setSubscriptionPlan(mostRecentSubscriptionData.role);
                setsubscriptionFinalStateSet(true);
                return true;
              } else {
                setHasSubscribed(false);
                setSubscriptionPlan("Free Basics");
                setsubscriptionFinalStateSet(true);
                return false;
              }
            }
            // If the status is active, then there is a subscription:
            if (mostRecentSubscriptionData.status === "active") {
              setHasSubscribed(true);
              setSubscriptionPlan(mostRecentSubscriptionData.role);
              setsubscriptionFinalStateSet(true);
              return true;
            }

            // Else the subscription is not present or is no longer valid:
            setHasSubscribed(false);
            setSubscriptionPlan("Free Basics");
            setsubscriptionFinalStateSet(true);
            return false;
          }
        };

        // Create the DB listener:
        const unsuscribe = query.onSnapshot(
          checkIfActiveSubscription,
          (err) => {
            setsubscriptionFinalStateSet(false);
            setHasSubscribed(false);
            setSubscriptionPlan("Free Basics");
            setsubscriptionFinalStateSet(true);
          }
        );
        return () => {
          setHasSubscribed(false);
          setSubscriptionPlan("Free Basics");
          setsubscriptionFinalStateSet(true);
          unsuscribe();
        };
      }
    }
  }, [initialized]);

  // END OF SUBSCRIPTION LOGIC:

  // COURSE PURCHASES LOGIC:   // https://stackoverflow.com/questions/65385423/react-hooks-firebase-firestore-onsnapshot-correct-use-of-a-firestore-listene:
  const [purchasedCourses, setPurchasedCourses] = useState({});
  const [purchasedCoursesFinalStateSet, setpurchasedCoursesFinalStateSet] =
    useState(false);
  const [hasUpgradedPlan, setHasUpgradedPlan] = useState(false);

  useEffect(() => {
    setpurchasedCoursesFinalStateSet(false);

    if (initialized && !user?.uid) {
      setPurchasedCourses({});
      setpurchasedCoursesFinalStateSet(true);
      return;
    } else {
      if (initialized && user?.uid) {
        // Callback function:
        const extractAllCoursePurchasesForUser = async (snapshot) => {
          setpurchasedCoursesFinalStateSet(false);

          // No courses:
          if (snapshot.empty) {
            setpurchasedCoursesFinalStateSet(true);
            return setPurchasedCourses({});
          }

          // Courses:
          const allPaidCourses = await Promise.all(
            snapshot.docs.map(async (doc, index) => {
              const data = await doc.data();
              const localObject = {};

              // Loop over all of the courseID's
              if (!data.metadata) return {};

              try {
                const metadata = eval(data.metadata.courseIDs);
                if (typeof metadata !== "undefined") {
                  for (const course of metadata) {
                    localObject[course] = data;
                  }
                }
              } catch (error) {
                console.error(error);
                return {};
              }

              // 1. Check for an Early Supporter Purchase, this checkout session must only contain the Early Supporter:
              const metadataPriceIDs = eval(data.metadata.priceIDs);

              if (
                typeof metadataPriceIDs !== "undefined" &&
                Array.isArray(metadataPriceIDs) &&
                metadataPriceIDs[0] === stripePriceIds["Early Supporter"] &&
                hasUpgradedPlan === false
              ) {
                // If the subscipriton plan is Early Supporter, or the user has already upgraded, then we don't need to do anything:
                // Check in the database if the user has already upgraded:
                const checkingPlanSnapshot = await firebase
                  .firestore()
                  .collection("customers")
                  .doc(user?.uid)
                  .collection("subscriptions")
                  .where("status", "==", "active")
                  .where("role", "==", "Early Supporter")
                  .limit(1)
                  .get();

                if (
                  !checkingPlanSnapshot.empty ||
                  subscriptionPlan === "Early Supporter"
                ) {
                  if (subscriptionPlan !== "Early Supporter") {
                    setHasSubscribed(true);
                    setSubscriptionPlan("Early Supporter");
                  }
                  setHasUpgradedPlan(true);
                } else {
                  // If the current subscription is set to Early Supporter, then we don't need to update the plan:
                  // This ensures that the subscription useEffect is finished before we update any future state:
                  if (subscriptionPlan !== "Early Supporter") {
                    // There is an update to the subscription, turn it to false:
                    // - If it is, check the same user, reset the subscriptionStatus state to false.
                    // - Check to see if they have an active subscription, cancel it if so.
                    // Updating the purchased courses:
                    const mostRecentSubscriptionDocument = await firebase
                      .firestore()
                      .collection("customers")
                      .doc(user?.uid)
                      .collection("subscriptions")
                      .where("status", "==", "active")
                      .orderBy("created", "desc")
                      .limit(1)
                      .get();

                    try {
                      // Only upgrade and delete previous if they aren't already an early supporter:
                      if (!mostRecentSubscriptionDocument.empty) {
                        const subscriptionName =
                          await mostRecentSubscriptionDocument.docs[0].data()
                            .role;
                        if (subscriptionName !== "Early Supporter") {
                          // 2.2 Subscribe them to the $0 monthly Early Subscriber price.
                          // Subscribe the customer ID to the $0 monthly:
                          const customerRef = await firebase
                            .firestore()
                            .collection("customers")
                            .doc(user?.uid)
                            .get();

                          if (customerRef.exists) {
                            // Get the customer ID from FireStore:
                            const customerID = await customerRef.data()
                              .stripeId;

                            // Create the $0 monthly subscription plan:
                            await axios.post(
                              "/api/stripe/subscribe-customer-to-lifetime/",
                              {
                                customerID,
                              }
                            );

                            // 2.3 Cancel their last subscription plan, if there last plan was not an early supporter:
                            const subscriptionID =
                              await mostRecentSubscriptionDocument.docs[0].id;

                            await axios.post(
                              "/api/stripe/cancel-subscription-plan/",
                              {
                                subscriptionID,
                              }
                            );

                            // Update ReactJS state:
                            setHasSubscribed(true);
                            setSubscriptionPlan("Early Supporter");
                            setHasUpgradedPlan(true);
                          }
                        }
                      }

                      // Else there is no subscription, check if we are upgrading from the Free Basics plan:
                      else if (subscriptionPlan === "Free Basics") {
                        const customerRef = await firebase
                          .firestore()
                          .collection("customers")
                          .doc(user?.uid)
                          .get();

                        if (customerRef.exists) {
                          // Get the customer ID from FireStore:
                          const customerID = await customerRef.data().stripeId;
                          // Create the $0 monthly subscription plan:
                          await axios.post(
                            "/api/stripe/subscribe-customer-to-lifetime/",
                            {
                              customerID,
                            }
                          );

                          // Update ReactJS state:
                          setHasSubscribed(true);
                          setSubscriptionPlan("Early Supporter");
                          setHasUpgradedPlan(true);
                        }
                      }
                    } catch (error) {
                      setHasUpgradedPlan(true);
                      console.error(error);
                    }
                  }
                }
              }

              return localObject;
            })
          );

          // Flatten the array of courses into a single global object:
          //@ts-ignore
          const flattenedPaidCourses = Object.assign(...allPaidCourses);

          // Use the setState callback
          setPurchasedCourses(flattenedPaidCourses);
          setpurchasedCoursesFinalStateSet(true);
        };

        // Updating the purchased courses:
        const db = firebase.firestore();
        const query = db
          .collection("customers")
          .doc(user.uid)
          .collection("payments")
          .where("status", "==", "succeeded");

        const unsubscribe = query.onSnapshot(
          extractAllCoursePurchasesForUser,
          (err) => {
            setpurchasedCoursesFinalStateSet(false);
            setPurchasedCourses({});
            setpurchasedCoursesFinalStateSet(true);
            console.error(err);
          }
        );
        return () => {
          setPurchasedCourses({});
          setpurchasedCoursesFinalStateSet(true);
          unsubscribe();
        };
      }
    }
  }, [initialized]);

  // END OF COURSE PURCHASES LOGIC:

  // LOGIN LOGIC:

  useEffect(() => {
    let localEmail = window.localStorage.getItem("email");

    const unsubscribe = firebase.auth().onIdTokenChanged(async (user: User) => {
      if (!user) {
        // Destroy the cookie for firebase_id_token:
        nookies.destroy(null, "firebase_id_token");
      } else {
        // Set cookies if they haven't been set:
        setCookies(user);
        const eventFired = await checkForUserEvent(user, "userIDSet");
        if (!eventFired && user?.uid) {
          gtmUserIDSet(user?.uid);
          updateUserInRealTimeDB(user, "userIDSet", "set");
        }
      }

      setInitialized(false);
      // No user logged in:
      if (!user) {
        // If this is a click from a magic Link
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
          // Additional state parameters can also be passed via URL.
          // This can be used to continue the user's intended action before triggering
          // the sign-in operation.
          // Get the email if available. This should be available if the user completes
          // the flow on the same device where they started it.
          setEmail("");

          if (!localEmail) {
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again. For example:
            localEmail = window.prompt(
              "Please provide your email for confirmation"
            );
          }
          if (localEmail) {
            // The client SDK will parse the code from the link for you:
            firebase
              .auth()
              .signInWithEmailLink(localEmail, window.location.href)
              .then(() => {
                // This is a sign in link, so parse it and log user in:
                window.localStorage.removeItem("email");
                setEmail("");
                router.push(window.location.pathname);
              })
              .catch((error) => {
                addNotification({
                  style: "danger",
                  title: "Error logging in",
                  body: String(error),
                });
                setUser(null);
                setMeta(null);
                setEmail(localEmail);
              });
          }
          // If it isn't a sign in with email link, so we can safely set the user as null:
        } else {
          // Clear cookies as no user logged in and no magic link click
          setUser(null);
          setMeta(null);
        }
      }
      /////////////////////////////////////
      // User is either logged in or not //
      /////////////////////////////////////

      if (user?.uid) {
        // Delete the logout events:
        window.localStorage.removeItem("logout");
        updateUserInRealTimeDB(user, "logout", "set", false, false);
        const providerName = user?.providerData[0]?.providerId;

       
        // TODO check if they have already logged in with the same email but with a different provider
        // and then link the accounts:
        // https://firebase.google.com/docs/auth/web/account-linking#link-email-address-and-password-credentials-to-a-user-account

        // Set placeholders if no displayName or photoURL
        const hasCompletedSignUp = await firebase
          .auth()
          .currentUser.getIdTokenResult(true)
          .then((idTokenResult) => {
            return !!idTokenResult.claims.hasCompletedSignUp;
          })
          .catch((error) => {
            console.log(error);
          });

        if (!hasCompletedSignUp) {
          const eventFired = await checkForUserEvent(user, "signup");
          if (!eventFired) {
            gtmSignUp(providerName);
            updateUserInRealTimeDB(user, "signup", "set", true);
          }
          const displayName = user.displayName || getBotName();
          const newUserInformation = {
            displayName,
            photoURL:
              user.photoURL ||
              "https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/images%2Fuser.png?alt=media&token=882a29e8-42a0-4a11-87dc-4f326cf2b53e",
          };
          await user
            .updateProfile(newUserInformation)
            .then(async () => {
              // Update successful.
              const { displayName, photoURL } = newUserInformation;
              const { email, refreshToken, emailVerified, uid } = user;
              const { creationTime, lastSignInTime } = user.metadata;

              const savedMeta = {
                displayName,
                photoURL,
                email,
                refreshToken,
                emailVerified,
                uid,
                creationTime,
                lastSignInTime,
              };

              // Client side check for the user's email verification status:
              firebase
                .auth()
                .currentUser.getIdToken(/* forceRefresh */ true)
                .then(function (idToken) {
                  // Send token to your backend via HTTPS + Add user to mailchimp
                  sendNewUserToMailerLite(email, displayName, idToken);
                })
                .catch(function (error) {});

              // addNotification({
              //   style: "info",
              //   title: "Added to Email List",
              //   body: "You'll get a welcome email and one update per week. You can opt out at any time by clicking the unsubscribe link in an email.",
              // });

              // Creating the user in the database:
              const database = firebase.database();
              const userRef = database.ref("users/" + user.uid);
              await userRef.update(savedMeta);

              // Merging localStorage with the user:
              const localData = window.localStorage.getItem("vex-responses");
              if (localData) {
                const coursesRef = firebase
                  .database()
                  .ref("users/" + user.uid)
                  .child("courses");

                coursesRef.update(JSON.parse(localData));
                window.localStorage.removeItem("vex-responses");
                addNotification({
                  style: "info",
                  title: "Your progress has been saved",
                  body: "We have saved your previous progress to this account.",
                });
              }

              // Setting a custom claim for the user:
              await axios.post("/api/auth/set-custom-claim/", { user });

              setUser(user);
              setMeta({ ...meta, ...savedMeta });
              setInitialized(true);

              // addNotification({
              //   style: "info",
              //   title: `Welcome ${savedMeta.displayName}`,
              //   body: `Your display name is ${savedMeta.displayName}.`,
              // });
            })
            .catch(function (error) {
              addNotification({
                style: "danger",
                title: "Error creating account",
                body: String(error),
              });
              console.log(error);
            });
        } else {
          const eventFired = await checkForUserEvent(user, "login");
          if (!eventFired) {
            gtmLogin(providerName);
            updateUserInRealTimeDB(user, "login", "set", true);
          }

          // Getting existing metadata:
          const database = firebase.database();
          const userRef = database.ref("users/" + user.uid);
          userRef.on("value", (snapshot) => {
            let userMeta = {};
            if (snapshot.exists()) {
              userMeta = snapshot.val();
            }
            setUser(user);
            setMeta({ ...meta, ...userMeta });
            setInitialized(true);
          });
        }
      }

      // Check to see if there is not a user:
      if (!user?.uid) {
        setInitialized(true);
        setUser(null);
        setMeta(null);
        return;
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
  // END OF LOGIN LOGIC:

  return (
    <AuthContext.Provider
      value={{
        user,
        email,
        meta,
        initialized,
        hasSubscribed,
        hasUpgradedPlan,
        subscriptionPlan,
        subscriptionFinalStateSet,
        purchasedCourses,
        purchasedCoursesFinalStateSet,
        setMeta,
        setEmail,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
