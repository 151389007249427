import React, { useState, useContext, createContext, ReactNode } from "react";
import SearchUI from "@/components/algolia/searchUI";

// Define the type for the context value
type SearchContextType = {
  isSearchOpen: boolean,
  setIsSearchOpen: (isOpen: boolean) => void,
};

// Create the context with the default value
// prettier-ignore
const SearchContext = createContext<SearchContextType>({
  isSearchOpen: false,
  setIsSearchOpen: () => {}
});

// Define the type for the props of SearchContextProvider
type SearchContextProviderProps = {
  children: ReactNode,
};

export const SearchContextProvider = ({
  children,
}: SearchContextProviderProps) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <SearchContext.Provider value={{ isSearchOpen, setIsSearchOpen }}>
      {children}
      <SearchUI isSearchOpen={isSearchOpen} setIsSearchOpen={setIsSearchOpen} />
    </SearchContext.Provider>
  );
};

export const useSearchModal = (): SearchContextType =>
  useContext(SearchContext);
